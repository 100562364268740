import { LayerProps } from 'react-map-gl';
import { Dataset, DatasetData } from 'weatherlayers-gl/client';

import { LonLatArray } from './common';
import { Incident } from './incident';
import { Station } from './station';

export type LonLat = {
  lon: number;
  lat: number;
};

export type GDACoordinates = {
  easting: number;
  northing: number;
  zone: number;
};

export type CoordinatesForDisplay = {
  coordinateSystem: 'UTM (GDA2020)' | 'GPS (WGS84)';
  coordinateString: string;
};

type MapPopupType = 'stationDisabled' | 'triangulation' | 'station' | 'incident';

export const MAP_POPUP_TYPES: Record<string, MapPopupType> = {
  STATION_DISABLED: 'stationDisabled',
  TRIANGULATION: 'triangulation',
  STATION: 'station',
  INCIDENT: 'incident',
};

export type MapSourceData = GeoJSON.FeatureCollection;

export interface Powerline {
  OBJECTID: number;
  ID: string;
  TYPE: string;
  STATUS: string;
  NAICS_CODE: string;
  NAICS_DESC: string;
  SOURCE: string;
  SOURCEDATE: string;
  VAL_METHOD: string;
  VAL_DATE: string;
  OWNER: string;
  VOLTAGE: number;
  VOLT_CLASS: string;
  INFERRED: string;
  SUB_1: string;
  SUB_2: string;
  SHAPE__Length: number;
  mapbox_clip_start: number;
  mapbox_clip_end: number;

  LINECATEGO: string;
  LineCat: string;
  FID: string;
}

export interface GtfaPlantation {
  MANAGER: string;
  LOCALITY: string;
  CPT: string;
  LANDUSE: string;
  SPECIES: string;
  PYEAR: number;
  STATE: string;
}

/**
 * A generic Map Popup Properties type
 * - Longer term we'll be consuming this data and won't be creating types for each mapping
 * - Moving forward we'll use this to avoid creating new types
 */
export interface MapPopupProperties {
  [name: string]: string | number;
}

export type MapPopupInfo = Station[] | Incident[] | LonLat[] | null;

export type CustomPopupInfo = Station | Incident | LonLat;

export interface MapSearchResult {
  features?: MapSearchResultFeature[];
  type: string;
  query: string[];
  attribution: string;
}

export interface MapSearchResultFeature {
  id: string;
  center: LonLatArray;
  place_name: string;
  type: string;
  place_type: string[];
  relevance: number;
  properties: {
    wikidata: string;
    category: string;
    landmark: boolean;
    address: string;
    foursquare: string;
    maki: string;
  };
  text_en: string;
  language_en: string;
  place_name_en: string;
  text: string;
  language: string;
  matching_text: string;
  matching_place_name: string;
  geometry: {
    coordinates: LonLatArray;
    type: string;
  };
  context: {
    id: string;
    text_en: string;
    text: string;
    wikidata?: string;
    language_en?: string;
    language?: string;
    short_code?: string;
  }[];
}

export interface MapAsset {
  type: string;
  geometry: {
    type: string;
    coordinates: number[][];
  };
  properties: Powerline &
    GtfaPlantation & {
      MinDistanceInMeters: number;
      nearestPoint?: {
        geometry: {
          type: string;
          coordinates: number[];
        };
        properties: {
          dist: number;
          index: number;
          location: number;
        };
        type: string;
      };

      // PGE Assets: Substation
      Substation?: string;
      SubType?: string;

      // PGE Assets: Location
      LOCATIONTYPE?: string;
      Type?: string;
      NAME?: string; // PGE Assets: hydroplant
    };
}

export interface MapViewport {
  latitude: number;
  longitude: number;
  zoom: number;
  bearing?: number;
  pitch?: number;
}

export type MapMode = 'light' | 'dark' | 'map' | 'satellite';

export interface RfsBound {
  ContactNr: string;
  FID: number;
  RFS_REGION: string;
  Shape_Area: number;
  Shape_Leng: number;
  lon: number;
  lat: number;
}

export interface LayerVisibility {
  [key: string]: boolean;
}

export type WeatherLayerNames = 'temperature' | 'humidity' | 'wind-speed' | 'wind-gust';

export interface WeatherLayerSelection {
  raster: WeatherLayerNames | null;
  animation: boolean;
}
export type WeatherMapLayerData = {
  [key in WeatherLayerNames]: { imageData: DatasetData; datasetMetadata: Dataset };
};

export type PopupSize = {
  width: number;
  height: number;
};

export type IncidentColors = {
  POSSIBLE: string;
  PRESCRIBED: string;
  CONFIRMED: string;
  CLOSED: string;
  DISMISSED: string;
};

export interface MapLayerPopupInfo {
  [key: string]: MapPopupProperties;
}

export interface MapLayerPopupTitles {
  powerline: string;
  pgeFacilities: string;
  pgeOverheadMainlines: string;
  pgeOverheadTaplines: string;
  pgeUndergroundMainlines: string;
  pgeUndergroundTaplines: string;
  gtfaDispatchZones: string;
  gtfaPlantationsABP: string;
  gtfaPlantationsAKD: string;
  gtfaPlantationsGPFL: string;
  gtfaPlantationsGTFP: string;
  gtfaPlantationsHVP: string;
  gtfaPlantationsOFO: string;
  gtfaPlantationsPFO: string;
  gtfaPlantationsSFM: string;
  gtfaPlantationsTPPL: string;
  gtfaFocMapGrid: string;
  gtfaCfaMapGrid: string;
  gtfaCfsMapGrid: string;
  rffPlantations: string;
  foricoPlantations?: string;
  forico50k?: string;
  tfsBrigade?: string;
}
interface GenericAttributes {
  [key: string]: string | number;
}
export interface MapLayerPopupContent {
  info: GenericAttributes;
  title: string;
}

export interface MapLayerConfig {
  id: string;
  version: number;
  config: {
    source: {
      location: string[];
      type?: string;
      promoteId?: string;
    };
    displayInfo: {
      title: string;
      subTitle?: string;
      icon?: string;
      properties?: {
        [key: string]: string | number;
      }[];
      popupInfoPriority?: number;
    };
    layerTemplate?: {
      name: string;
      [key: string]: string;
    };
    /** A manually created style object for the layer */
    layers?: LayerProps[];
    'source-layer'?: string;
    isVisibleByDefault?: boolean;
    isNearbyAssetsEnabled?: boolean;
  };
}

export interface SelectedFeature {
  source: string;
  id: string;
}
