import { INCIDENT_LABEL, IncidentLabel, MapAssetDataSet, Ptz } from 'types';

export const INPUT_DEBOUNCE_TIMEOUT = 350;
/** The max number of incidents to return from the incident API */
export const INCIDENT_LIMIT = 1000;

export const API_TRACE_HEADER: string = 'correlation-id';
/** The header to indicate MFA status */
export const API_HEADER_MFA: string = 'x-pano-mfa';
export const API_TIMEOUT: number = 30000; // Timeout 30 seconds for all API calls.
/** @todo If cache size goes beyond 1000, show warning now. */
export const API_CACHE_SIZE: number = 1000;
export const LOOP_INTERVAL_60S: number = 60_000; // 60 seconds loop interval
export const LOOP_INTERVAL_5M: number = 300_000; // 5 minutes loop interval
export const OZ_SESSION_LIVE_MAX_MS = Number(localStorage?.getItem('OZ_SESSION_LIVE_MAX_MS')) || 10 * 60 * 1000; // An OZ session lasts only for max 10 minutes
/** Stations are considered offline if no images in last 10 minutes */
export const DEFAULT_PLAYER_PERIOD: number = 10 * 60;
/** Earliest possible date for an incident
 * @jira https://panoai.atlassian.net/browse/RD-504 */
export const MIN_INCIDENT_DATE: Date = new Date(1000000000000); // 09/09/2001
export const USER_FIELD: string = 'PANO_USER';
export const EMPTY_FUNCTION = (): void => {};

export const INCIDENT_FILTER_QUERY_FIELD: string = 'incidentFilterQuery';
export const INITIAL_FILTERS: IncidentLabel[] = [
  INCIDENT_LABEL.POSSIBLE,
  INCIDENT_LABEL.CLOSED,
  INCIDENT_LABEL.DISMISSED,
  INCIDENT_LABEL.PRESCRIBED,
  INCIDENT_LABEL.CONFIRMED,
];
export const INCIDENT_ORDERS: { [x: string]: number } = {
  [INCIDENT_LABEL.DISMISSED]: 0,
  [INCIDENT_LABEL.CLOSED]: 1,
  [INCIDENT_LABEL.PRESCRIBED]: 2,
  [INCIDENT_LABEL.CONFIRMED]: 3,
  [INCIDENT_LABEL.POSSIBLE]: 4,
};

export const DEFAULT_PAN_TILT_ZOOM: Ptz = {
  pan: 0,
  tilt: 0,
  zoom: 1,
};

export const PGE_ASSET_DATASETS: MapAssetDataSet[] = [
  MapAssetDataSet.PgeOverheadMainlines,
  MapAssetDataSet.PgeOverheadTaplines,
  MapAssetDataSet.PgeUndergroundMainlines,
  MapAssetDataSet.PgeUndergroundTaplines,
  MapAssetDataSet.PgeLocation,
  MapAssetDataSet.PgeSubstation,
  MapAssetDataSet.PgeHydroPlant,
];
