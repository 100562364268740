import { GEOJSON_SERVER } from 'config/base';
import { LayerProps } from 'react-map-gl';
import { atom, selector, selectorFamily } from 'recoil';
import { MapLayerConfig } from 'types';

import { rsmMapLayersVisibility } from '../mapLayers';

/**
 * The map layer configs available for the current user
 */
export const rsmMapLayerConfigs = atom<MapLayerConfig[]>({
  key: 'rsmMapLayerConfigs',
  default: [],
});

/**
 * Returns whether there are dynamic layers
 */
export const rsmHasDynamicLayers = selector({
  key: 'rsmHasDynamicLayers',
  get: ({ get }) => {
    const mapLayerConfigs = get(rsmMapLayerConfigs);

    return !!mapLayerConfigs.length;
  },
});

/**
 * @description These are the ids of all the dynamic layers
 */
export const rsmDynamicLayerIds = selector({
  key: 'rsmDynamicLayerIds',
  get: ({ get }) => {
    const mapLayerConfigs = get(rsmMapLayerConfigs);

    return mapLayerConfigs.map((mapLayerConfig) => mapLayerConfig.id);
  },
});

/**
 * This is an array of all the ids for visible dynamic map layers
 */
export const rsmVisibleDynamicLayerIds = selector({
  key: 'rsmVisibleDynamicLayerIds',
  get: ({ get }) => {
    const mapLayerConfigs = get(rsmMapLayerConfigs);
    const layerVisibility = get(rsmMapLayersVisibility);

    return mapLayerConfigs.map((mapLayerConfig) => mapLayerConfig.id).filter((id) => !!layerVisibility[id]);
  },
});

/**
 * @description Returns a prioritized list of the maylayer ids sorted by popupInfoPriority
 */
export const rsmDynamicLayerPrioritization = selector({
  key: 'rsmDynamicLayerPrioritization',
  get: ({ get }) => {
    const mapLayerConfigs = get(rsmMapLayerConfigs);
    const newMapLayerConfigs = [...mapLayerConfigs];

    newMapLayerConfigs.sort((firstConfig, secondConfig) => {
      return firstConfig.config.displayInfo.popupInfoPriority - secondConfig.config.displayInfo.popupInfoPriority;
    });

    return newMapLayerConfigs.map((mapLayerConfig) => mapLayerConfig.id);
  },
});

/**
 * @description Returns the Map Layer Config with the given id
 */
export const rsmMapLayerConfigById = selectorFamily<MapLayerConfig, MapLayerConfig['id']>({
  key: 'rsmMapLayerConfigById',
  get:
    (mayLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfigs = get(rsmMapLayerConfigs);

      return mapLayerConfigs.find((mapLayerConfig) => mapLayerConfig.id === mayLayerConfigId);
    },
});

/**
 * @description Returns the template type for the Map Layer Config with the given id
 */
export const rsmTemplateTypeById = selectorFamily<string, MapLayerConfig['id']>({
  key: 'rsmTemplateTypeById',
  get:
    (mayLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfig = get(rsmMapLayerConfigById(mayLayerConfigId));

      return mapLayerConfig.config?.layerTemplate?.name;
    },
});

/**
 * @description Returns the main color to use for the stlying template for the Map Layer Config with the given id
 */
export const rsmTemplateColorById = selectorFamily<string, MapLayerConfig['id']>({
  key: 'rsmTemplateColorById',
  get:
    (mayLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfig = get(rsmMapLayerConfigById(mayLayerConfigId));

      return mapLayerConfig.config?.layerTemplate?.color;
    },
});

/**
 * @description Returns the selected color to use for the stlying template for the Map Layer Config with the given id
 */
export const rsmTemplateSelectedColorById = selectorFamily<string, MapLayerConfig['id']>({
  key: 'rsmTemplateSelectedColorById',
  get:
    (mayLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfig = get(rsmMapLayerConfigById(mayLayerConfigId));

      return mapLayerConfig.config?.layerTemplate?.selectedColor;
    },
});

/**
 * @description Returns the promote id for the Map Layer Config with the given id
 */
export const rsmPromoteIdById = selectorFamily<string, MapLayerConfig['id']>({
  key: 'rsmPromoteIdById',
  get:
    (mayLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfig = get(rsmMapLayerConfigById(mayLayerConfigId));

      return mapLayerConfig.config.source.promoteId;
    },
});

/**
 * @description Returns the custom styling objects for the layer
 */
export const rsmLayerStylingById = selectorFamily<LayerProps[], MapLayerConfig['id']>({
  key: 'rsmLayerStylingById',
  get:
    (mayLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfig = get(rsmMapLayerConfigById(mayLayerConfigId));

      return mapLayerConfig.config?.layers;
    },
});

/**
 * @description Returns the tiles array for the layer
 */
export const rsmTilesById = selectorFamily<string[], MapLayerConfig['id']>({
  key: 'rsmTilesById',
  get:
    (mayLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfig = get(rsmMapLayerConfigById(mayLayerConfigId));
      const tilesPathname = mapLayerConfig?.config?.source?.location?.reduce((path, bucketName) => {
        if (!path) {
          return bucketName;
        }
        return `${path}+${bucketName}`;
      });

      if (!mapLayerConfig) {
        return [];
      }

      return [`${GEOJSON_SERVER}/${tilesPathname}/latest/{z}/{x}/{y}.pbf`];
    },
});

/**
 * @description Returns the title for the given MapLayerConfig
 */
export const rsmTitleById = selectorFamily<string, MapLayerConfig['id']>({
  key: 'rsmTitleById',
  get:
    (mayLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfig = get(rsmMapLayerConfigById(mayLayerConfigId));

      return mapLayerConfig.config.displayInfo.title;
    },
});

/**
 * @description Returns the subtitle for the given MapLayerConfig
 */
export const rsmSubTitleById = selectorFamily<string, MapLayerConfig['id']>({
  key: 'rsmSubTitleById',
  get:
    (mayLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfig = get(rsmMapLayerConfigById(mayLayerConfigId));

      return mapLayerConfig.config.displayInfo.subTitle || '';
    },
});

/**
 * @description Returns the icon to use for the given MapLayerConfig
 */
export const rsmIconById = selectorFamily<string, MapLayerConfig['id']>({
  key: 'rsmIconById',
  get:
    (mayLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfig = get(rsmMapLayerConfigById(mayLayerConfigId));

      return mapLayerConfig.config.displayInfo.icon;
    },
});
